import React from "react"
import { graphql } from "gatsby"

import Layout from "@/components/Layout"
import SEO from "@/components/SEO"
import VideoPage, { VideoType } from "@/components/VideoPage"

type PageProps = {
  data: {
    sanityVideo: VideoType
  }
}

export const pageQuery = graphql`
query Video4Query {
  sanityVideo(position: {eq: 4}) {
    title
    description
    placeholder {
      asset {
        url
      }
    }
    videoUrl
  }
}
`

const Video4: React.FC<PageProps> = ({ data }) => {
  return (
    <Layout
      sectionOne={
        <>
          <video
            id="bgvid"
            className="absolute inset-0 w-full h-full object-cover -z-50 sm:hidden"
            playsInline
            autoPlay
            muted
            loop
          >
            <source src="/videos/rbai_giving_day_mobile.mp4" type="video/mp4" />
          </video>
          <video
            id="bgvid"
            className="absolute inset-0 w-full h-full object-cover -z-50  hidden sm:block"
            playsInline
            autoPlay
            muted
            loop
          >
            <source src="/videos/rbai_giving_day.mp4" type="video/mp4" />
          </video>
          <div className="container text-white pt-60 pb-4 flex flex-col gap-4 items-center text-center">
            <div>
              <h1 className="pb-2 md:pb-12 responsive-heading">
                {data.sanityVideo.title}
              </h1>
              {/* <Link
                className="font-bold bg-primary brightness-90 hover:brightness-110 self-center mx-auto py-3 px-2"
                to={routes.donate()}
              >
                TOTAL RAISED{" "}
                <b>
                  £{getCMScontent(data, "Amount raised")?.toLocaleString("en")}
                </b>
              </Link> */}
              <div className="pt-8 flex justify-center">
                <img src="/images/down.png" alt="Down arrow" />
              </div>
            </div>
          </div>
        </>
      }
    >
      <SEO title={data.sanityVideo.title} />

      <VideoPage video={data.sanityVideo} />
    </Layout>
  )
}

export default Video4
